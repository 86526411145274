import Swal from "sweetalert2";

export default {
  info(title, msg) {
    Swal.fire({
      title: title,
      text: msg,
      icon: "info",
      confirmButtonColor: '#3699FF',
      cancelButtonColor: '#F64E60',
    });
  },
  success(title, msg) {
    Swal.fire({
      title: title,
      text: msg,
      icon: "success",
      confirmButtonColor: '#3699FF',
      cancelButtonColor: '#F64E60',
    });
  },
  warning(title, msg) {
    Swal.fire({
      title: title,
      text: msg,
      icon: "warning",
      confirmButtonColor: '#3699FF',
      cancelButtonColor: '#F64E60',
    });
  },
  error(title, msg) {
    Swal.fire({
      title: title,
      text: msg,
      icon: "danger",
      confirmButtonColor: '#3699FF',
      cancelButtonColor: '#F64E60',
    });
  },
  question(title, msg) {
    return Swal.fire({
      title: title,
      text: msg,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: '#3699FF',
      cancelButtonColor: '#F64E60',
      cancelButtonText: "Annluer"
    });
  },
};
