<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Liste des fichiers</h3>
        </div>
      </div>
      <b-overlay :show="isLoading" rounded="sm">
        <div class="card-body">
          <div>
            <a
              @click="removeFiles()"
              class="btn btn-danger font-weight-bolder font-size-sm mr-3"
            >
              <i class="fas fa-trash"></i> Supprimer
            </a>
          </div>
          <br />
          <DxDataGrid
            @initialized="saveGridInstance"
            :data-source="dataSource"
            :columns="columns"
            :show-row-lines="true"
            :word-wrap-enabled="true"
            :show-borders="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :row-alternation-enabled="false"
            :hover-state-enabled="true"
            :column-auto-width="true"
            :show-column-lines="true"
            :selection="{ mode: 'multiple' }"
            @selection-changed="onSelectionChanged"
            @cell-prepared="onCellPrepared"
            key-expr="uid"
          >
            >
            <DxFilterRow :visible="true" />

            <DxHeaderFilter :visible="true" :allow-search="true" />
            <!-- <DxStateStoring :enabled="true" type="localStorage" storage-key="medicineList" /> -->
            <DxPager
              :show-page-size-selector="true"
              :allowed-page-sizes="[5, 10, 20]"
            />
            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="true" />
            <DxPaging :page-size="10" />
            <DxSearchPanel :visible="true" />
            <DxSummary>
              <DxTotalItem
                column="filename"
                summary-type="count"
                display-format="{0}"
              />
            </DxSummary>
          </DxDataGrid>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PosService from "../../../services/pos.service";
import SweetAlert2Helper from "../../../helpers/sweetAlert2.helper";

import {
  DxDataGrid,
  //DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxSearchPanel,
  DxPaging,
  //DxStateStoring,
  DxPager,
  DxHeaderFilter,
  DxFilterRow,
  DxSummary,
  DxTotalItem,
} from "devextreme-vue/data-grid";

export default {
  name: "pos-detail",
  components: {
    DxDataGrid,
    //DxColumn,
    DxGrouping,
    DxGroupPanel,
    DxSearchPanel,
    DxPaging,
    //DxStateStoring,
    DxPager,
    DxHeaderFilter,
    DxFilterRow,
    DxSummary,
    DxTotalItem,
  },
  data() {
    return {
      isLoading: false,
      filesGuids: [],
      dataSource: [],
      columns: [
        { dataField: "filename", caption: "Fichier" },
        { dataField: "senderPos", caption: "Émetteur" },
        {
          dataField: "type",
          caption: "Type",
          dataType: "string",
        },
        { dataField: "sentDate", caption: "Date d'envoi", dataType: "date" },
        {
          dataField: "state",
          caption: "Etat",
          calculateCellValue: function (rowData) {
            switch (rowData.state) {
              case 0:
                return "Envoyé";
              case 1:
                return "En attente";
              case 2:
                return "Reçu";
              case 3:
                return "Erreur";
            }
          },
        },
        {
          dataField: "length",
          caption: "Taille",
          alignment: "right",
          calculateCellValue: (rowData) => {
            return this.bytesToSize(rowData.length);
          },
        },

        { dataField: "lastError", caption: "Message d’erreur" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Accueil", route: "/dashboard" },
      { title: "Detail" },
    ]);
    this.getPosFiles();
  },
  methods: {
    saveGridInstance: function (e) {
      this.dataGridInstance = e.component;
    },
    onSelectionChanged(e) {
      this.filesGuids = e.selectedRowKeys;
    },
    // onRowPrepared(e) {
    //   if (e.rowType === "data") {
    //     if (
    //       e.data.senderSyncId &&
    //       e.data.receiverSyncId &&
    //       e.data.senderSyncId == e.data.receiverSyncId
    //     ) {
    //       e.rowElement.style.backgroundColor = "#f4516c0f";
    //       e.rowElement.className = e.rowElement.className.replace(
    //         "dx-row-alt",
    //         ""
    //       );
    //     }
    //   }
    // },
    onCellPrepared: function (e) {
      if (e.rowType == "data" && e.column.dataField == "filename") {
        if (  e.data.senderSyncId &&
          e.data.receiverSyncId &&
          e.data.senderSyncId == e.data.receiverSyncId) {
           e.cellElement.style.color = "red";
        } 
      }
    },
    getPosFiles() {
      this.isLoading = true;
      PosService.getPosFiles(this.$route.params.guid)
        .then((result) => {
          this.dataSource = result.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    removeFiles() {
      if (this.filesGuids.length == 0) {
        SweetAlert2Helper.info(
          "Attention",
          "Selectionner au moins un fichier."
        );
        return;
      }
      SweetAlert2Helper.question(
        "Attention",
        "Voulez-vous supprimer les fichier selectionnés ?."
      ).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          PosService.geRemoveFiles(this.filesGuids)
            .then(() => {
              this.isLoading = false;
              this.getPosFiles();
            })
            .catch(() => {
              SweetAlert2Helper.error("Erreur", "Une erreur est survenue.");
              this.isLoading = false;
            });
        }
      });
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
  },
};
</script>
